import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
} from "../../helpers/";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";

interface UserData {
  payload: {
    fullname: string;
    email: string;
    password: string;
    username: string;
    businessName: string;
    address: string;
    phone: string;
    checkboxsignup:boolean;
  };
  type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
// function* login({
//   payload: { username, password },
//   type,
// }: UserData): SagaIterator {
//   try {
//     const response = yield call(loginApi, { username, password });
//     console.log(response)
//     if(response.data.status == true){
//       const user = response.data;
//       // api.setLoggedInUser(user);
//       // setAuthorization(user["token"]);

//       // yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
//     }
//     // NOTE - You can change this according to response format from your api
//   } catch (error: any) {
//     yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
//     api.setLoggedInUser(null);
//     setAuthorization(null);
//   }
// }
function* login({ payload: { username, password }, type }: UserData): SagaIterator {
  try {
    const response = yield call(loginApi, { username, password });
    console.log(response);
    if (response.data.status === true) {
      if(response.data.user.is_otp_verified === 1){
        if(response.data.user.is_active === 1){
          console.log('here')
          const user = response.data;
          api.setLoggedInUser(response.data);
          setAuthorization(response.data.token);
          yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, response.data.user));
        }else{
          yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, 'Please Wait for admin to approve your account'));
        }
      }else{
        const user = response.data;
          api.setLoggedInUser(response.data);
        window.location.href = '/auth/confirm';
      }
    } else {
      yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, response.data.message)); // Send specific error message
    }
  } catch (error: any) {
    let errorMessage = 'Your login credentials are not correct';

    if (error.response && error.response.data && error.response.data.errors) {
      errorMessage = error.response.data.errors.join(', ');
    }
    else if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message; 
    }

    // Dispatch the extracted error message
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, errorMessage)); // Send specific error message

    // Clear logged-in user data
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}


/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    yield call(logoutApi);
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({
  payload: { fullname, email, password,username ,businessName ,address ,phone,checkboxsignup },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { fullname, email, password ,username ,businessName ,address ,phone,checkboxsignup });
    if (response.data.status === true) {
      api.setLoggedInUser(response.data);
      setAuthorization(response.data.token);
      yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, response.data.user));
    }
  } catch (error: any) {
    let errorMessage = 'Something went wrong. Please try again.';

    if (error.response && error.response.data && error.response.data.errors) {
        // Assuming error.response.data.errors is an array
        errorMessage = error.response.data.errors.join(', '); // You can format this based on the API structure
    }
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER,errorMessage));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
  ]);
}

export default authSaga;
